import React from 'react'
import {useSpring} from 'react-spring'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import LinksLayout from 'src/components/LinksLayout'

const CameraEquipment = () => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const linkDataList: AnimatedButtonProps[] = [

  ]

  return <LinksLayout linkDataList={linkDataList}/>
}

export default CameraEquipment